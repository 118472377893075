import { unCamelCase, slugify } from '@buddy-technology/buddy_helpers';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const VERSION = process.env.REACT_APP_VERSION;
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
const UMAMI_CODE = process.env.REACT_APP_UMAMI_CODE;
const UMAMI_URL = process.env.REACT_APP_UMAMI_URL || 'https://aggregate-analytics.netlify.app/script.js';

const formatPremiumForGA = (premium) => {
	const formattedPremium = Number(parseFloat(premium.replace('$', '')).toFixed(2));
	return formattedPremium;
};

const convertToSnakeCase = (str) => slugify(unCamelCase(str), '_');

export const reportError = (error, tags) => {
	const { Sentry } = window;
	if (typeof tags === 'object' && Object.keys(tags).length) {
		Sentry?.withScope((scope) => {
			scope.setTags(tags);
			Sentry.captureException(error);
		});
	} else {
		Sentry?.captureException(error);
	}
};

/**
 * Loads and configures google analytics
 * @param  {String} trackingCode
 * @param  {String} partnerID
 * @param  {String} ionID
 */
export const loadGA = (trackingCode, partnerID, ionID) => {
	const s = document.createElement('script');
	s.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingCode}`);
	s.setAttribute('async', 1);
	const x = document.getElementsByTagName('script')[0];
	x.parentNode.insertBefore(s, x);
	const s2 = document.createElement('script');
	// this is my hacky way of adding a script to the document head
	s2.text = `
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			window.dataLayer.push(arguments);
		}

	`;
	x.parentNode.insertBefore(s2, x);

	const { gtag } = window;

	gtag('config', trackingCode, {
		cookie_prefix: '_buddy',
		cookie_domain: 'none',
		partner_id: partnerID,
		ion_id: ionID,
		send_page_view: false,
		cookie_flags: 'SameSite=None;Secure',
		send_to: trackingCode,
		page_title: `${ionID} | ${partnerID}`,
	});
	gtag('js', new Date());
};

export const loadUmami = () => {
	const s = document.createElement('script');
	s.setAttribute('src', UMAMI_URL);
	s.setAttribute('data-website-id', UMAMI_CODE);
	s.setAttribute('do-not-track', 'true'); // should be string, not boolean
	s['data-website-id'] = UMAMI_CODE;
	s.async = 1;
	const x = document.getElementsByTagName('script')[0];
	x.parentNode.insertBefore(s, x);
};

/**
 * Track user events to google analytics.
 * @param  {String} eventType
 * @param  {Object} data
 */
export const trackUserEvents = (eventType, data) => {
	const { gtag, umami } = window;
	try {
		const {
			channelUrl,
			checkoutError,
			checkoutStatus,
			ion,
			premium,
			pricing,
			viewId,
		} = data;

		const gaData = { ...data, event_category: 'itr_event' };
		const umamiData = { ion, channelUrl };
		const gaLabel = convertToSnakeCase(eventType);
		// just in case premium is already a number, we'll stringify it here. (The below removes '$' and returns number)
		const premiumToSend = formatPremiumForGA(`${premium}`);

		switch (eventType) {
			case 'onViewChange':
				gtag('event', 'screen_view', { ...gaData, screen_name: gaData.viewId, app_name: 'offer-element' });
				umami.track(eventType, { ...umamiData, viewScreen: viewId });
				break;
			case 'onScrollToView':
				gtag('event', 'screen_view', { ...gaData, screen_name: gaData.viewId, app_name: 'offer-element' });
				umami.track(eventType, { ...umamiData, viewScreen: viewId });
				break;
			case 'onQuote':
				// pricing comes in as a number, no formatting necessary.
				gtag('event', 'quote', { ...gaData, value: pricing });
				umami.track(eventType, { ...umamiData, price: pricing });
				break;
			case 'onCheckout':
			// GA tracks three types of checkout events: begin_checkout, purchase, and failed_checkout
				if (checkoutError) {
					gtag('event', 'failed_checkout', {
						...gaData, currency: 'USD', value: premiumToSend, error_message: checkoutError,
					});
					umami.track('failedCheckout', {
						...data, currency: 'USD', value: premiumToSend, error_message: checkoutError,
					});
				} else {
					const label = checkoutStatus === 'start' ? 'beginCheckout' : 'purchase';
					gtag('event', convertToSnakeCase(label), {
						...gaData, currency: 'USD', value: premiumToSend,
					});
					umami.track(label, {
						...data, currency: 'USD', value: premiumToSend,
					});
				}
				break;
			default:
				gtag('event', gaLabel, gaData);
				umami.track(eventType, data);
				break;
		}
	} catch (error) {
		reportError(error, { type: 'analyticsError' });
	}
};

/**
 * Loads Sentry for error tracking
 */
export const loadSentry = () => {
	const asyncSentry = document.createElement('script');

	asyncSentry.setAttribute('src', `https://js.sentry-cdn.com/${SENTRY_DSN}.min.js`);
	asyncSentry.setAttribute('data-lazy', 'no');
	asyncSentry.setAttribute('crossOrigin', 'anonymous');
	asyncSentry.setAttribute('async', 'true');
	asyncSentry.setAttribute('id', 'sentry-async-script');

	// eslint-disable-next-line no-undef
	asyncSentry.onload = () => Sentry.onLoad(() => {
		// eslint-disable-next-line no-undef
		Sentry.init({
			environment: SENTRY_ENV,
			debug: SENTRY_ENV === 'development',
			release: `offer-element@${VERSION}`,
		});
	});
	const head = document.querySelector('head');
	const scripts = head.getElementsByTagName('script');
	// this puts Sentry as the very first script.
	head.insertBefore(asyncSentry, scripts[0]);
};
